<template>
  <div id="openAccount">
    <div class="content-box">
      <div class="inner">
        <div class="title">{{ $t('verificationStatus.accOpeningVerification.title') }}</div>
        <div class="sub-title">
          <i18n path="verificationStatus.accOpeningVerification.subTitle">
            <template v-slot:link>
              <a href="https://puprime.com/account-types" target="_blank">{{
                $t('verificationStatus.accOpeningVerification.here')
              }}</a>
            </template>
          </i18n>
        </div>
        <div class="liveaccount-box">
          <div v-if="!success">
            <div class="form-box">
              <el-form class="form-box" :model="form" :rules="formRules" ref="form">
                <el-row :gutter="40">
                  <el-col :sm="12" :xs="24">
                    <el-form-item prop="tradingPlatform">
                      <Selection
                        v-model="form.tradingPlatform"
                        :placeholder="$t('common.formValidation.answerReq')"
                        :options="tradingPlatformOptions"
                        filterable
                        selectType="underline"
                        dataTestId="platform"
                      >
                        <template #title>
                          {{
                            $t('register.accOpeningVerification.createLiveAccount.choose', {
                              dynamic: $t('register.accOpeningVerification.createLiveAccount.tradingPlatform')
                            })
                          }}
                        </template>
                      </Selection>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :xs="24">
                    <el-form-item prop="accountType">
                      <Cascader
                        :title="
                          $t('register.accOpeningVerification.createLiveAccount.choose', {
                            dynamic: $t('register.accOpeningVerification.createLiveAccount.accountType')
                          })
                        "
                        v-model="accountTypeSelection"
                        :options="accountTypeList"
                      ></Cascader>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="40">
                  <el-col :sm="12" :xs="24">
                    <el-form-item prop="currency">
                      <Selection
                        v-model="form.currency"
                        :placeholder="$t('common.formValidation.answerReq')"
                        :options="currencyOptions"
                        filterable
                        selectType="underline"
                        dataTestId="currency"
                      >
                        <template #title>
                          {{
                            $t('register.accOpeningVerification.createLiveAccount.choose', {
                              dynamic: $t('register.accOpeningVerification.createLiveAccount.accountCurrency')
                            })
                          }}
                        </template>
                      </Selection>
                    </el-form-item>
                  </el-col>
                  <el-col :sm="12" :xs="24">
                    <el-form-item prop="notes">
                      <TextInput
                        type="text"
                        :placeholder="$t('register.accOpeningVerification.createLiveAccount.mamNumberPlaceholder')"
                        v-model="form.notes"
                        dataTestId="mamNumber"
                      >
                        <template #title>
                          {{ $t('register.accOpeningVerification.createLiveAccount.mamNumber') }}
                        </template>
                      </TextInput>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="40">
                  <el-col class="mb-4" v-if="minDeposit || isIslamicAccountType">
                    <div class="info-box">
                      <div class="info-img">
                        <img src="@/assets/images/info-icon.png" class="label-icon" />
                      </div>
                      <div class="info-box-text">
                        <div v-if="minDeposit">
                          <span>{{ $t('openAdditAcc.minDeposit') }}</span>
                          <span
                            ><span class="text-higlight">{{ minDeposit }}</span> {{ form.currency }}</span
                          >
                        </div>
                        <div v-if="isIslamicAccountType">
                          <div v-if="isIslamic">
                            <strong>{{ $t('openAdditAcc.importantNote') }}</strong>
                            <span v-html="$t('openAdditAcc.islamicTnc')"></span>
                          </div>
                          <div v-else>
                            <strong>{{ $t('openAdditAcc.importantNote') }}</strong>
                            <span>
                              <i18n path="openAdditAcc.nonIslamicTnc">
                                <template v-slot:link>
                                  <a
                                    href="https://helpcenter.puprime.com/hc/en-001/articles/5551547411983-When-would-an-administration-fee-be-charged-in-my-Islamic-Trading-Account"
                                    target="_blank"
                                    >{{ $t('openAdditAcc.here') }}</a
                                  >
                                </template>
                              </i18n>
                            </span>
                          </div>
                          <div>
                            <span>{{
                              $t('register.accOpeningVerification.createLiveAccount.islamicTooltip', {
                                platform: $config.info.fullName
                              })
                            }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col>
                    <el-form-item class="mb-3" prop="checkStatus" :inline-message="true">
                      <div class="checkBox pug-checkbox">
                        <div class="d-flex align-items-center">
                          <el-checkbox
                            id="checkbox"
                            name="checkStatus"
                            v-model="form.checkStatus"
                            data-testid="checkStatus"
                          >
                          </el-checkbox>
                          <i18n path="openAdditAcc.byTicking" tag="div" class="tnc">
                            <template v-slot:tnc>
                              <p class="showTnc" @click="showTNC = !showTNC" data-testid="showTNC">
                                {{ $t('openAdditAcc.tnc') }}
                              </p>
                            </template>
                          </i18n>
                        </div>
                        <ul
                          class="tnc-details"
                          v-show="showTNC"
                          v-html="
                            $t($config.openLiveAccount.getAgreeCondition(regulator), {
                              platform: $config.info.fullName,
                              regulatorUrl: GLOBAL_REFERRAL_SITE
                            })
                          "
                        ></ul>
                      </div>
                    </el-form-item>
                    <div v-if="showCopyTradingTnc">
                      <el-form-item class="mb-3" prop="checkCopyTradingTnc" :inline-message="true">
                        <div class="checkBox pug-checkbox">
                          <div class="d-flex align-items-center">
                            <el-checkbox
                              id="checkbox"
                              name="checkCopyTradingTnc"
                              v-model="form.checkCopyTradingTnc"
                              data-testid="checkCopyTradingTnc"
                            >
                            </el-checkbox>
                            <i18n path="openAdditAcc.byTickingCopyTrading" tag="div" class="tnc">
                              <template v-slot:tnc>
                                <a
                                  class="showTnc"
                                  target="_blank"
                                  :href="copyTradingHref"
                                  data-testid="showCopyTradingTnc"
                                  >{{ $t('openAdditAcc.tnc') }}</a
                                >
                              </template>
                            </i18n>
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>

                <div class="action-area mt-5">
                  <Button
                    buttonType="default"
                    customClasses="action-button"
                    dataTestId="confirm"
                    @click="submit('form')"
                  >
                    <div>{{ $t('common.button.confirm') }}</div>
                  </Button>
                </div>
              </el-form>
            </div>
          </div>
          <Result :selfImg="true" v-else>
            <template v-slot:img>
              <div class="steps">
                <img src="@/assets/images/openAccount/step01.png" alt="" />
                <div class="line"></div>
                <img src="@/assets/images/openAccount/step02.png" alt="" />
                <div class="line"></div>
                <img src="@/assets/images/openAccount/step03.png" alt="" />
              </div>
            </template>
            <p v-html="$t('openAdditAcc.succ')"></p>
          </Result>

          <DialogVersion3 :visible="pammVisible">
            <div class="dialog-body text-center">
              <p><span class="required_icon">*</span>{{ $t('openAdditAcc.pamm') }}</p>
              <div class="line"></div>
              <p class="red">*{{ $t('openAdditAcc.pammConfirm') }}</p>
              <div class="btn-box">
                <el-button class="btn-default" @click="isShowDialog = false">
                  {{ $t('common.button.confirm') }}
                </el-button>
                <el-button class="btn-blue" @click="resetType()">{{ $t('common.button.cancel') }}</el-button>
              </div>
            </div>
          </DialogVersion3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import Selection from '@/components/common/Selection.vue';
import TextInput from '@/components/common/TextInput.vue';
import Cascader from '@/components/common/Cascader.vue';
import Result from '@/components/Result';
import DialogVersion3 from '@/components/dialog/v3/Dialog';
import Hover from '@/components/Hover';
import {
  apiGetAutoStartTradingThreshold,
  apiApplyAdditionalAccount,
  apiQueryMetaTraderAccountDetails
} from '@/resource';
import { checkAgreedTNC, checkAgreedCopyTradingTNC } from '@/util/validation';
import { islamicCountry } from '@/constants/islamicCountry';
import { highRiskCountry } from '@/constants/highRiskCountry';

export default {
  name: 'openLiveAccount',
  components: { Result, DialogVersion3, Hover, Button, Selection, Cascader, TextInput },
  data() {
    return {
      form: {
        tradingPlatform: null,
        accountType: null,
        currency: null,
        checkStatus: false,
        checkCopyTradingTnc: false,
        notes: ''
      },
      formRules: {
        tradingPlatform: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.createLiveAccount.tradingPlatform')
            }),
            trigger: 'change'
          }
        ],
        accountType: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.createLiveAccount.accountType')
            }),
            trigger: 'change'
          }
        ],
        currency: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.createLiveAccount.accountCurrency')
            }),
            trigger: 'change'
          }
        ],
        checkStatus: [{ validator: checkAgreedTNC, trigger: 'change' }],
        checkCopyTradingTnc: [{ validator: checkAgreedCopyTradingTNC, trigger: 'change' }]
      },
      accountTypeSelection: null,
      copyTradingHref: '',
      success: false,
      showTNC: false,
      isShowDialog: true,
      threshold: {},
      isExistCopyTrading: false
    };
  },
  watch: {
    'form.accountType': {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (oldVal) this.form.currency = '';
      }
    },
    accountTypeSelection: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (Array.isArray(newVal)) {
            this.form.accountType = newVal[newVal.length - 1];
          } else {
            this.form.accountType = newVal;
          }

          if (newVal === 'PAMM') {
            this.isShowDialog = true;
          }
        }
      }
    }
  },
  mounted() {
    this.getAutoStartTradingThreshold();
    this.queryMetaTraderAccountDetails();
  },
  methods: {
    isValidByCountry(obj, countryCode) {
      if (obj.restrictCountries) {
        return !obj.restrictCountries.includes(countryCode);
      }
      return true;
    },
    filterChildren(children, countryCode) {
      return children.filter(child => this.isValidByCountry(child, countryCode));
    },
    getAutoStartTradingThreshold() {
      apiGetAutoStartTradingThreshold().then(resp => {
        resp.data.forEach(element => {
          if (!this.threshold[element.alias])
            Object.assign(this.threshold, {
              [element.alias]: {
                [element.currency]: {
                  amount: element.threshold
                }
              }
            });
          else if (!this.threshold[element.alias][element.currency])
            Object.assign(this.threshold[element.alias], {
              [element.currency]: {
                amount: element.threshold
              }
            });
        });
      });
    },
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let escapeString = this.escapeStringForJSON(this.form.notes);
          apiApplyAdditionalAccount({
            notes: escapeString,
            accountType: this.form.accountType,
            tradingPlatform: this.form.tradingPlatform,
            currency: this.form.currency
          }).then(resp => {
            if (resp.data.code === 0) {
              this.success = true;
            }
          });
        }
      });
    },
    escapeStringForJSON(string) {
      return string.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    },
    resetType() {
      if (
        this.accountTypeList.length > 0 &&
        Array.isArray(this.accountTypeList[0].children) &&
        this.accountTypeList[0].children.length > 0
      ) {
        this.accountTypeSelection = this.accountTypeList[0]?.children[0]?.value;
      } else {
        this.accountTypeSelection = this.accountTypeList[0]?.value;
      }
    },
    queryMetaTraderAccountDetails() {
      apiQueryMetaTraderAccountDetails({}).then(resp => {
        if (resp.data?.length > 0) {
          let result = resp.data;
          this.isExistCopyTrading = result.filter(item => item.serverCategory === '6').length > 0;
        }
      });
    }
  },
  computed: {
    tradingPlatformOptions() {
      let tradingPlatformData = this.$config.openLiveAccount.choosePlatform(this.regulator);

      if (this.isExistCopyTrading) {
        tradingPlatformData = tradingPlatformData.filter(item => item.value !== 'MTS');
      }

      // set default platform
      this.form.tradingPlatform = tradingPlatformData[0].value;

      return tradingPlatformData.map(item => ({
        label: this.$t(item.label),
        value: item.value
      }));
    },
    accountTypeList() {
      let typeList = this.$config.openLiveAccount.additionalAccountType;

      const result = typeList(this.regulator).filter(f => {
        if (this.form.tradingPlatform === 'MTS' && f.value === 'PAMM') {
          return false;
        }

        const isParentValid = this.isValidByCountry(f, this.countryCode);

        if (f.children && f.children.length > 0) {
          f.children = this.filterChildren(f.children, this.countryCode);
        }

        return isParentValid || (f.children && f.children.length > 0);
      });

      // set default accountType
      if (this.accountTypeSelection == null && result.length > 0) {
        if (result.length > 0 && result[0].hasOwnProperty('children') && result[0].children.length > 0) {
          this.form.accountType = result[0].children[0].value;
          this.accountTypeSelection = result[0].children[0].value;
        } else {
          this.form.accountType = result[0].value;
          this.accountTypeSelection = result[0].value;
        }
      }

      return result;
    },
    currencyOptions() {
      const result = this.$config.openLiveAccount.chooseCurrency(this.regulator, this.form.accountType);

      // check current choose accountType
      this.form.currency = result[0].value;

      return result;
    },
    minDeposit() {
      try {
        return this.threshold[this.form.accountType][this.form.currency].amount;
      } catch (e) {
        return null;
      }
    },
    showCopyTradingTnc() {
      this.copyTradingHref = 'https://www.puprime.com/copy_trading_terms_and_conditions_cp';

      if (highRiskCountry.includes(this.countryCode)) {
        this.copyTradingHref = 'https://www.puprime.net/copy_trading_terms_and_conditions_cp_net';
      }

      if (this.form.tradingPlatform === 'MTS') {
        return true;
      } else {
        return false;
      }
    },
    isIslamic() {
      return islamicCountry.includes(this.countryCode) ? true : false;
    },
    isIslamicAccountType() {
      const islamicAccountType = ['swapFreeStandardSTP', 'ISLAMIC_STP_CENT', 'swapFreeRawECN', 'ISLAMIC_ECN_CENT'];

      return islamicAccountType.includes(this.form.accountType) ? true : false;
    },
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    },
    pammVisible() {
      return this.isShowDialog && this.form.accountType === 'PAMM';
    }
  }
};
</script>

<style lang="scss" scoped>
.label-icon {
  width: 17px;
  height: 17px;
  position: relative;
  margin-left: 8px;
}

.hoverShow span {
  color: $white !important;
  font-size: inherit !important;
  font-weight: normal !important;
  margin-left: 0 !important;
}

.label-icon:hover + .hoverShow {
  display: flex !important;
  align-items: center;
  justify-content: center;
  top: calc(100% + 8px);
}

.title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sub-title {
  margin-bottom: 40px;

  /deep/ a {
    color: $pug-blue;
    text-decoration: underline;
  }
}

.liveaccount-box {
  border-radius: 8px;
  background-color: $white;
  max-width: 1200px;
  padding: 68px 40px;

  /deep/ .success {
    max-width: 904px;

    .steps {
      margin-bottom: 80px;

      img {
        margin-bottom: 0;
        display: inline-block;
      }

      .line {
        display: inline-block;
        border-width: $border-width;
        border-color: $blue;
        border-style: dashed;
        width: 157px;
        margin: 0 20px 0;
      }
    }

    .success-content {
      max-width: 600px;

      p {
        font-size: 15px;
        color: $dark-gray;
        line-height: 1.667;
        text-align: center;
      }
    }
  }

  @media screen and (min-width: 576px) {
    padding: 68px 120px;
  }
}

.checkBox {
  /deep/ .el-checkbox {
    display: contents;
  }

  .tnc-details {
    margin-top: 10px;
  }

  /deep/ .tnc {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    @include rtl-sass-prop(margin-left, margin-right, 15px);

    .showTnc {
      display: inline-block;
      color: $pug-blue;
      cursor: pointer;
    }
  }
  /deep/ ul {
    @include rtl-sass-prop(padding-left, padding-right, 40px);

    li {
      font-size: 14px;
      line-height: 28px;
      list-style-type: decimal;
      a {
        color: $primary;
        text-decoration: none;
      }
    }
  }
}

.action-area {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.action-button {
  width: 160px;
  height: 56px;
}

@media (max-width: 770px) {
  .action-area {
    flex-direction: column-reverse;
    gap: 16px;
  }
  .action-button {
    width: 100%;
  }
}

.text-higlight {
  color: $pug-blue;
}

.mr-2 {
  margin-right: 10px;
}

.info-box {
  display: flex;
  border-radius: 8px;
  background-color: #ebf4ff;
  padding: 14px 12px;
  align-items: center;

  /deep/ strong {
    font-weight: bold;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.info-img {
  margin-right: 10px;
  flex-shrink: 0;
  align-self: flex-start;
}

.info-box-text {
  div + div {
    margin-top: 20px;
  }
}

/deep/ .el-dialog__body {
  padding: 50px 50px 45px !important;

  p {
    font-size: 15px;
    color: #626d71;
    line-height: 1.8;
    word-break: normal;

    &.red {
      color: $red;
      margin-bottom: 40px;
    }
  }

  .line {
    border-width: 0.59px;
    border-color: rgb(160, 160, 160);
    border-style: dashed;
    margin: 20px 0;
  }

  .btn-box {
    display: grid;
    grid-template-columns: 45% 45%;
    column-gap: 10%;
    margin-bottom: 20px;
  }
}

#openAccount {
  .inner {
    max-width: 1200px;
  }
}
</style>
